.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 180px;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #f6a015;
    width: 100%;
    top: 65px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #f6a015;
    width: 100%;
    top: 65px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: white;
    border: 1px solid #f6a015;
    margin-bottom: 6px;
    color: #f6a015;
  }
  
  .stepper-item.active {
    //font-weight: bold;
  }

  .stepper-item.active .step-counter {
    background-color: white;
    span{
        padding: 4px 16px 4px 16px;
        border-radius: 50%;
        background-color: white;
        border: 1px dashed #f6a015;
        color: #f6a015;
    }
  }
  
  .stepper-item.completed .step-counter {
    background-color: white;
    span{
        padding: 4px 16px 4px 16px;
        border-radius: 50%;
        background-color: #f6a015;
        color: white;
    }
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #f6a015;
    width: 100%;
    top: 65px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }