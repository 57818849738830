@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import './asset/theme/pdf-theme';
@import './asset/theme/scrollbar';
@import './asset/theme/custom';
@import './asset/theme/stepper';
@import '~bootstrap/scss/bootstrap';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
html{
  font-size: 22px;
}
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'DB Helvethaica';
  src: url(./asset/font/DB-Helvethaica-X-v3.2.ttf) format('truetype');
}
body {
  background-color: white;
  font-family: 'DB Helvethaica', sans-serif !important;
}
.font-sarabun {
  font-family: "Sarabun", sans-serif !important;

}
.white-space-pre{
  white-space: pre;
}

.vh-100 {
  min-height: 100vh;
}

.bg-custom {
  background-color: #fcb134;
}

.custom-card {
  border-radius: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.custom-body {
  font-size: 0.875rem; /* 14px */
}

.custom-margin {
  margin-left: 5%;
  margin-right: 5%;
}

.logo {
  height: 35px;
  margin-right: 25px;
}
.click{
  &:hover{
    opacity: 0.8;
    cursor: pointer;
  }
  
}
.link-blue{
  color: #00A7CC;
  text-decoration: none;
  &:hover{
    opacity: 0.8;
    cursor: pointer;
    text-decoration: underline;
  }
}
.btn-link-2{
  border: none;
  background-color: white;
  text-decoration: underline;
  color: black;
  &:hover{
    background-color: #f2f2f2;
  }
}

.border-custom {
  border: 1px solid #e9eaec;
}

.progress-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.progress-modal.show {
  display: flex;
}

.progress-container {
  width: 300px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
}

.progress-header {
  margin-bottom: 16px;
}

.progress-body {
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  width: 100%;
  background-color: #24c163;
  animation: progress-animation 4s infinite alternate;
}

@keyframes progress-animation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  background: #ffffff;
  border-radius: 10px;
  margin: 40px 0px 10px;
  padding: 20px;
}
.content-2 {
  background: #e9eaec;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 0px 0px 0px 0px
}
.signature-panel {
  font-size: 12px;
}


.btn-link{
  border: none;
  background-color: white;
  text-decoration: underline;
  &:disabled{
    background-color: white !important;
  }
}

.btn-hover{
  border: none;
  background-color: white;
  text-decoration: underline;
  &:hover{
    background-color: #f2f2f2;
  }
}
.btn{
  &:disabled{
    border-color: #e5e5e5;
    background-color: #e5e5e5;
    color: #959595 !important;
    img{
      filter: brightness(0.5) !important;
    }
  }
}
.container-scroll-worklist{
  max-height: calc(100vh - 65vh);
  overflow-y: auto;
  
}