::-webkit-scrollbar {
    -webkit-appearance: auto;
  }
  
  ::-webkit-scrollbar:vertical {
    width: calc(0.7vw + 0.7vh);//15px;
  }
  
  ::-webkit-scrollbar:horizontal {
    height: calc(0.7vw + 0.7vh);
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }
  
  ::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
  } 